<div
  *ngIf="!formId"
  class="post-item-page"
  [ngClass]="{ 'page-content page-content__isLoading': !surveys }"
>
  <div class="page-content" *ngIf="surveys; else spinner">
    <h1 class="survey-name">{{ 'post.unstructured.add_survey.title' | translate }}</h1>
    <span class="info-message">
      {{ 'post.unstructured.add_survey.info' | translate : { source: post.source } }}</span
    >

    <div class="form-row">
      <mat-form-field appearance="outline">
        <mat-select
          [data-qa]="'select-survey-to-edit'"
          disableOptionCentering
          [(value)]="selectedSurvey"
          placeholder="{{ 'survey.choose_survey' | translate }}"
          (selectionChange)="formSelected()"
        >
          <mat-option *ngFor="let survey of surveys | async" [value]="survey">
            {{ survey.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</div>
<div *ngIf="formId" class="post-item-page">
  <div
    class="page-content"
    [ngClass]="{ 'page-content__isLoading': !formId || !(form && tasks.length) }"
  >
    <div class="translate-container">
      <h1 class="survey-name">{{ surveyName }}</h1>
      <div class="form-row" *ngIf="postLanguages.length">
        <mat-form-field appearance="outline" class="feed-page__control">
          <mat-select
            name="language"
            disableOptionCentering
            [(ngModel)]="selectedLanguage"
            [placeholder]="'app.select_language' | translate"
            (selectionChange)="selectLanguageEmit($event)"
            [data-qa]="'app.select_language'"
          >
            <mat-option *ngFor="let language of postLanguages" [value]="language">
              {{ language?.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mzima-client-button
          fill="clear"
          color="secondary"
          [iconOnly]="true"
          class="tooltip-btn"
          tabindex="-1"
          matTooltipPosition="above"
          matTooltip="View and add to this survey in another language"
        >
          <mat-icon icon svgIcon="tooltip"></mat-icon>
        </mzima-client-button>
      </div>
    </div>
    <form
      novalidate
      [formGroup]="form"
      autocomplete="off"
      (ngSubmit)="submitPost()"
      *ngIf="form && tasks.length; else spinner"
    >
      <div *ngFor="let task of tasks; let i = index" class="task-container">
        <div [ngStyle]="{ '--color': color }">
          <ng-container *ngIf="task.type !== 'post'">
            <h2 class="task-label">{{ task.translations[activeLanguage]?.label || task.label }}</h2>
          </ng-container>

          <div
            class="form-row"
            *ngFor="let field of task.fields | sortByField : 'priority' : 'asc'; let i = index"
          >
            <mat-label>
              <mat-icon
                aria-label="Field responses will be private"
                *ngIf="field.response_private"
                icon
                svgIcon="lock"
                class="field__icon"
              ></mat-icon>
              {{ field?.translations[activeLanguage]?.label || field?.label }}
              <span class="color-accent" *ngIf="field?.required">*</span>
            </mat-label>
            <span
              class="form-row__instruction"
              *ngIf="field?.instructions"
              [innerHTML]="field?.translations[activeLanguage]?.instructions || field?.instructions"
            >
            </span>

            <ng-container
              *ngIf="field.input === 'text' && (field.type === 'title' || field.type === 'varchar')"
            >
              <mat-form-field appearance="outline">
                <input
                  matInput
                  [formControlName]="field.key"
                  [data-qa]="field.label.replace(' ', '-') | lowercase"
                />
              </mat-form-field>
            </ng-container>

            <ng-container *ngIf="field.input === 'text' && field.type === 'description'">
              <mat-form-field appearance="outline">
                <textarea
                  matInput
                  cdkTextareaAutosize
                  cdkAutosizeMinRows="2"
                  cdkAutosizeMaxRows="5"
                  [formControlName]="field.key"
                  [data-qa]="field.label.replace(' ', '-') | lowercase"
                >
                </textarea>
              </mat-form-field>
            </ng-container>

            <ng-container *ngIf="field.input === 'textarea'">
              <mat-form-field appearance="outline">
                <textarea
                  matInput
                  cdkTextareaAutosize
                  cdkAutosizeMinRows="2"
                  cdkAutosizeMaxRows="5"
                  [formControlName]="field.key"
                  [data-qa]="field.label.replace(' ', '-') | lowercase"
                >
                </textarea>
              </mat-form-field>
            </ng-container>

            <ng-container *ngIf="field.input === 'tags'">
              <span
                class="related-post-list"
                *ngIf="getTotalCategoryCount(field.options); else noOptions"
              >
                <ul>
                  <li>
                    <mat-checkbox
                      [checked]="
                        form.get(field.key)?.value.length === getTotalCategoryCount(field.options)
                      "
                      (change)="toggleAllSelection($event, task.fields, field.key)"
                    >
                      {{ 'nav.select_all' | translate }}
                    </mat-checkbox>
                  </li>
                  <li *ngFor="let option of field.options; trackBy: trackById">
                    <mat-checkbox
                      *ngIf="!option.parent_id"
                      [checked]="form.get(field.key)?.value.includes(option.id)"
                      (change)="
                        onCheckChange(
                          $event,
                          field.key,
                          option.id,
                          field.options,
                          option.parent?.id
                        )
                      "
                    >
                      {{ option.tag }}
                    </mat-checkbox>
                    <ul *ngIf="option.children; trackBy: trackById">
                      <li *ngFor="let child of option.children">
                        <mat-checkbox
                          class="lvl-2"
                          [checked]="form.get(field.key)?.value.includes(child.id)"
                          (change)="
                            onCheckChange(
                              $event,
                              field.key,
                              child.id,
                              field.options,
                              child.parent?.id
                            )
                          "
                        >
                          {{ child.tag }}
                        </mat-checkbox>
                      </li>
                    </ul>
                  </li>
                </ul>
              </span>
            </ng-container>

            <ng-container *ngIf="field.input === 'date'">
              <mat-form-field appearance="outline">
                <input
                  matInput
                  [matDatepicker]="picker"
                  [formControlName]="field.key"
                  (focus)="picker.open()"
                  [data-qa]="field.label.replaceAll(' ', '-') | lowercase"
                  #input
                />
                <mzima-client-button
                  matSuffix
                  fill="clear"
                  color="secondary"
                  [iconOnly]="true"
                  (buttonClick)="clearField($event, field.key)"
                  *ngIf="form.get(field.key)?.value"
                >
                  <mat-icon icon svgIcon="close"></mat-icon>
                </mzima-client-button>
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker"
                  [data-qa]="field.label.replaceAll(' ', '-') + '-toggle' | lowercase"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker (closed)="input.blur()"></mat-datepicker>
                <mat-hint>{{
                  'form.default_date_hint' | translate : { field_name: field.label }
                }}</mat-hint>
              </mat-form-field>
            </ng-container>

            <ng-container *ngIf="field.input === 'datetime'">
              <mat-form-field appearance="outline">
                <input
                  matInput
                  [ngxMatDatetimePicker]="picker"
                  [formControlName]="field.key"
                  (focus)="picker.open()"
                  [data-qa]="field.label.replaceAll(' ', '-') | lowercase"
                  #input
                />
                <mzima-client-button
                  matSuffix
                  fill="clear"
                  color="secondary"
                  [iconOnly]="true"
                  (buttonClick)="clearField($event, field.key)"
                  *ngIf="form.get(field.key)?.value"
                >
                  <mat-icon icon svgIcon="close"></mat-icon>
                </mzima-client-button>
                <mat-datepicker-toggle
                  matSuffix
                  [for]="$any(picker)"
                  [data-qa]="field.label.replaceAll(' ', '-') + '-toggle' | lowercase"
                ></mat-datepicker-toggle>
                <ngx-mat-datetime-picker
                  #picker
                  [showSeconds]="true"
                  (closed)="input.blur()"
                ></ngx-mat-datetime-picker>
              </mat-form-field>
            </ng-container>

            <ng-container *ngIf="field.input === 'radio'">
              <mat-radio-group
                [attr.aria-label]="field.label"
                [formControlName]="field.key"
                *ngIf="field.options?.length; else noOptions"
                [data-qa]="
                  field.label.replaceAll(' ', '-').replace('(', '').replace(')', '') | lowercase
                "
              >
                <div class="radio-button" *ngFor="let option of field.options">
                  <mat-radio-button
                    [value]="option"
                    [data-qa]="
                      field.label.replaceAll(' ', '-').replace('(', '').replace(')', '') +
                        '-' +
                        option.replaceAll(' ', '-') | lowercase
                    "
                    *ngIf="option !== 'Other'"
                  >
                    {{ option }}
                  </mat-radio-button>
                  <div class="other" *ngIf="option === 'Other'">
                    <mat-radio-button
                      class="other__input__radio"
                      [value]="option"
                      (change)="otherRadio.focus()"
                    >
                    </mat-radio-button>
                    <mat-form-field appearance="outline">
                      <input
                        class="other__input"
                        #otherRadio="matInput"
                        matInput
                        type="text"
                        placeholder="Add other"
                        [formControlName]="'other' + field.key"
                        (focus)="selectOther($event, field)"
                      />
                    </mat-form-field>
                  </div>
                  <mat-error *ngIf="option === 'Other' && hasEmptyOther(field.key)">
                    Input cannot be empty
                  </mat-error>
                </div>
              </mat-radio-group>
            </ng-container>

            <ng-container *ngIf="field.input === 'checkbox'">
              <mat-selection-list
                [formControlName]="field.key"
                *ngIf="field.options?.length; else noOptions"
                [data-qa]="
                  field.label.replaceAll(' ', '-').replace('(', '').replace(')', '') | lowercase
                "
              >
                <ng-container *ngFor="let option of field.options">
                  <mat-list-option
                    color="primary"
                    [value]="option"
                    class="list-option"
                    checkboxPosition="before"
                    *ngIf="option !== 'Other'"
                    [data-qa]="
                      field.label.replaceAll(' ', '-').replace('(', '').replace(')', '') +
                        '-' +
                        option.replaceAll(' ', '-') | lowercase
                    "
                  >
                    {{ option }}
                  </mat-list-option>
                  <div class="other" *ngIf="option === 'Other'">
                    <mat-list-option
                      color="primary"
                      [value]="option"
                      class="list-option other__input__checkbox"
                      checkboxPosition="before"
                      (click)="otherCheckbox.focus()"
                    >
                    </mat-list-option>
                    <mat-form-field appearance="outline" #formfield>
                      <input
                        (keydown)="$event.stopPropagation()"
                        class="other__input"
                        matInput
                        #otherCheckbox="matInput"
                        type="text"
                        [formControlName]="'other' + field.key"
                        (focus)="selectOther($event, field)"
                      />
                    </mat-form-field>
                  </div>
                  <mat-error *ngIf="option === 'Other' && hasEmptyOther(field.key)">
                    Input cannot be empty
                  </mat-error>
                </ng-container>
              </mat-selection-list>
            </ng-container>

            <ng-container *ngIf="field.input === 'select'">
              <mat-form-field appearance="outline" *ngIf="field.options?.length; else noOptions">
                <mat-select
                  [formControlName]="field.key"
                  disableOptionCentering
                  [data-qa]="'post-item-post-select' + field.key"
                >
                  <mat-option
                    *ngFor="let option of field.options"
                    [value]="option"
                    [data-qa]="'post-item-post-select-option' + option"
                  >
                    {{ option }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </ng-container>

            <ng-container *ngIf="field.input === 'number'">
              <mat-form-field appearance="outline">
                <input
                  matInput
                  [formControlName]="field.key"
                  type="number"
                  [pattern]="field.type === 'int' ? '[0-9]*' : ''"
                  [data-qa]="
                    field.label.replace(' ', '-').replace('(', '').replace(')', '') | lowercase
                  "
                />
              </mat-form-field>
            </ng-container>

            <ng-container *ngIf="field.type === 'media'">
              <span class="form-row__instruction" *ngIf="maxImageSize">
                Max size: {{ field.config.maxUploadSize }} MB
              </span>
              <div *ngIf="field.input === 'upload'">
                <app-image-uploader
                  [formControlName]="field.key"
                  [hasCaption]="field.config.hasCaption"
                  (change)="maxSizeError = false"
                  [requiredError]="
                    form.get(field.key)?.touched && form.get(field.key)?.hasError('photoRequired')
                  "
                  [maxSizeError]="maxSizeError"
                >
                </app-image-uploader>
              </div>
              <div *ngIf="field.input === 'image'">
                <app-media-uploader
                  [formControlName]="field.key"
                  media="image"
                  [hasCaption]="field.config.hasCaption"
                  [maxUploadSize]="field.config.maxUploadSize"
                  (change)="maxSizeError = false"
                  [requiredError]="
                    form.get(field.key)?.touched && form.get(field.key)?.hasError('photoRequired')
                  "
                >
                </app-media-uploader>
              </div>
              <div *ngIf="field.input === 'audio'">
                <app-media-uploader
                  [formControlName]="field.key"
                  media="audio"
                  [maxUploadSize]="field.config.maxUploadSize"
                  (change)="maxSizeError = false"
                  [requiredError]="
                    form.get(field.key)?.touched && form.get(field.key)?.hasError('photoRequired')
                  "
                >
                </app-media-uploader>
              </div>
              <div *ngIf="field.input === 'document'">
                <app-media-uploader
                  [formControlName]="field.key"
                  [maxUploadSize]="field.config.maxUploadSize"
                  media="document"
                  (change)="maxSizeError = false"
                  [requiredError]="
                    form.get(field.key)?.touched && form.get(field.key)?.hasError('photoRequired')
                  "
                >
                </app-media-uploader>
              </div>
            </ng-container>

            <ng-container *ngIf="field.input === 'video'">
              <mat-form-field appearance="outline">
                <input
                  matInput
                  [formControlName]="field.key"
                  [data-qa]="field.label.replace(' ', '-') | lowercase"
                  #videoInput
                />
              </mat-form-field>
              <mat-error
                *ngIf="
                  form.get(field.key)?.hasError('invalidvideourl') &&
                  !form.get(field.key)?.hasError('required')
                "
              >
                Invalid video embed url
              </mat-error>
              <iframe
                *ngIf="form.get(field.key)?.value"
                width="400"
                height="250"
                [src]="generateSecurityTrustUrl(formValidator.videoUrlPreview(videoInput))"
                [hidden]="!videoInput.value.length"
              ></iframe>
            </ng-container>

            <ng-container *ngIf="field.input === 'location'">
              <app-location-select
                [zoom]="12"
                [isEditPost]="isEditPost"
                [location]="form.get(field.key)?.value"
                [required]="isLocationRequired(field)"
                [type]="post?.source?.toLowerCase() || 'default'"
                (locationChange)="changeLocation($event, field.key)"
              ></app-location-select>
            </ng-container>

            <ng-container *ngIf="field.input === 'relation'">
              <p>{{ 'survey.related_post_info' | translate }}</p>
              <div class="related-post-selected" *ngIf="selectedRelatedPost">
                <div class="related-post-content">
                  <span>{{ selectedRelatedPost.id }}: {{ selectedRelatedPost.title }}</span>
                  <div
                    class="related-post-delete"
                    [data-qa]="'btn-post-item-delete-relation-post'"
                    (click)="deleteRelatedPost(field, selectedRelatedPost.id)"
                  >
                    <mat-icon svgIcon="close"></mat-icon>
                  </div>
                </div>
              </div>

              <div class="relation-container" *ngIf="!selectedRelatedPost">
                <mat-form-field appearance="outline">
                  <input
                    matInput
                    type="text"
                    [(ngModel)]="relationSearch"
                    [ngModelOptions]="{ standalone: true }"
                    [data-qa]="field.label.replaceAll(' ', '-') | lowercase"
                  />
                </mat-form-field>
                <mzima-client-button
                  fill="outline"
                  color="secondary"
                  class="relation-button-search"
                  (buttonClick)="relationSearchPosts()"
                  [data-qa]="field.label.replaceAll(' ', '-') + '-search' | lowercase"
                >
                  {{ 'post.relation.search' | translate }}
                </mzima-client-button>
              </div>

              <span class="related-post-list" *ngIf="relatedPosts?.length">
                <ul>
                  <li *ngFor="let post of relatedPosts">
                    <mat-checkbox (change)="chooseRelatedPost($event, field, post)">
                      {{ post.id }}: {{ post.title }}
                    </mat-checkbox>
                  </li>
                </ul>
              </span>
            </ng-container>

            <ng-container *ngIf="field.input === 'markdown'">
              <mat-form-field appearance="outline">
                <textarea
                  matInput
                  [formControlName]="field.key"
                  cdkTextareaAutosize
                  cdkAutosizeMaxRows="5"
                  [placeholder]="field.default"
                  [data-qa]="field.label.replace(' ', '-') | lowercase"
                ></textarea>
              </mat-form-field>
            </ng-container>
            <mat-error *ngIf="form.get(field.key)?.invalid && form.get(field.key)?.touched">
              <p *ngIf="form.get(field.key)?.hasError('required')">
                {{ field.label }} field is a required field *
              </p>
              <p *ngIf="form.get(field.key)?.hasError('minlength')">
                {{ field.label }} field is too short, use at least 2 characters
              </p>
              <p *ngIf="form.get(field.key)?.hasError('pattern')">
                {{ field.label }} field is invalid
              </p>
              <p *ngIf="form.get(field.key)?.hasError('specialCharacters')">
                {{ field.label }} field contains special characters
              </p>
            </mat-error>
          </div>

          <ng-container *ngIf="task.type !== 'post'">
            <div
              class="task__info-banner task__info-banner-warning"
              *ngIf="task.show_when_published"
            >
              <div>
                <mat-icon
                  icon
                  svgIcon="info-circle"
                  class="task__info-icon task__info-icon-warning"
                ></mat-icon>
              </div>
              <p>{{ 'survey.task_visible_when_published' | translate }}</p>
            </div>

            <div
              class="task__info-banner task__info-banner-warning"
              *ngIf="task.task_is_internal_only"
            >
              <div>
                <mat-icon
                  icon
                  svgIcon="info-circle"
                  class="task__info-icon task__info-icon-warning"
                >
                </mat-icon>
              </div>
              <div>
                <p>{{ 'survey.marked_for_internal.header' | translate }}</p>
                <p>{{ 'survey.marked_for_internal.body' | translate }}</p>
              </div>
            </div>

            <!---------------------------------------------------------------------------------
            Depending on if a task is marked as completed or not (through the toggle),
            the id for that task is added to or removed from "this.taskForm.value" accordingly.
            Task info banner's color change (for an individual task) is controlled by whether that
            task's id is present or absent in "this.taskForm.value" - i.e. more like the
            ngIf value is supplied directly by the form in "real time" 
            ----------------------------------------------------------------------------------->
            <div
              class="task__info-banner"
              [ngClass]="{
                'task__info-banner-completed': taskForm.value[task.id],
                'task__info-banner-warning': !taskForm.value[task.id]
              }"
            >
              <div>
                <mat-icon
                  icon
                  [svgIcon]="taskForm.value[task.id] ? 'thumb-up' : 'info-circle'"
                  class="task__info-icon"
                  [ngClass]="{
                    'task__info-icon-completed': taskForm.value[task.id],
                    'task__info-icon-warning': !taskForm.value[task.id]
                  }"
                >
                </mat-icon>
              </div>
              <div>
                <p>
                  {{
                    (taskForm.value[task.id]
                      ? 'survey.task_is_marked_as.completed'
                      : 'survey.task_is_marked_as.not_completed'
                    ) | translate : { taskName: task.label }
                  }}
                </p>
              </div>
            </div>
            <!---->

            <div class="form-row">
              <mat-slide-toggle
                class="task-complete"
                name="accept-survey"
                [required]="task.reqiured"
                labelPosition="after"
                [checked]="task.completed"
                (change)="taskComplete(task, $event)"
              >
                {{ 'post.task_completed' | translate }}
                <span class="color-accent" *ngIf="task.required">*</span>
              </mat-slide-toggle>
            </div>
          </ng-container>
        </div>
      </div>

      <div class="form-controls-panel" [ngClass]="{ 'form-controls-panel--sticky': isDesktop }">
        <mzima-client-button
          fill="outline"
          color="secondary"
          [data-qa]="'btn-post-item-previous'"
          (buttonClick)="previousPage()"
          [disabled]="form.disabled"
        >
          {{ 'app.cancel' | translate }}
        </mzima-client-button>
        <mzima-client-button
          type="submit"
          [data-qa]="'btn-post-item-submit'"
          [disabled]="
            form.disabled ||
            form.invalid ||
            emptyLocation ||
            submitted ||
            (!requireApproval || post?.status === 'published' ? taskForm.invalid : false)
          "
        >
          {{ 'app.submit' | translate }}
        </mzima-client-button>
      </div>
    </form>
  </div>
</div>

<ng-template #spinner>
  <app-spinner class="spinner"></app-spinner>
</ng-template>

<ng-template #noOptions>
  <span class="no-options">{{ 'survey.no_options_for_this_field' | translate }}</span>
</ng-template>
