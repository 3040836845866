<mzima-client-button
  tabindex="-1"
  fill="outline"
  [iconOnly]="true"
  color="light-gray"
  [data-qa]="'btn-close'"
  class="modal__close-btn"
  [mat-dialog-close]="false"
  ariaLabel="{{ 'modal.button.close' | translate }}"
>
  <mat-icon icon svgIcon="close"></mat-icon>
</mzima-client-button>

<div [ngClass]="{ confirm: data.buttonSuccess }">
  <app-lottie-animation
    *ngIf="data.buttonSuccess"
    [path]="'/assets/lottie/success-animation.json'"
  ></app-lottie-animation>

  <div class="confirm-content">
    <h2 mat-dialog-title *ngIf="data.title" class="confirm-title">{{ data.title }}</h2>
    <mat-dialog-content>
      <div
        [innerHTML]="data.description"
        class="confirm-description"
        *ngIf="data.description"
      ></div>
    </mat-dialog-content>
  </div>

  <mat-dialog-actions [align]="data.buttonSuccess ? 'center' : 'end'">
    <mzima-client-button
      *ngIf="data.buttonSuccess"
      mat-dialog-close="true"
      [data-qa]="'btn-confirm-success'"
    >
      <span>{{ data.buttonSuccess }}</span>
    </mzima-client-button>
    <ng-container *ngIf="!data.buttonSuccess">
      <mzima-client-button
        [fill]="data.isCancelDestructive ? 'solid' : 'outline'"
        [color]="data.isCancelDestructive ? 'danger' : 'secondary'"
        [mat-dialog-close]="false"
        [data-qa]="'btn-confirm-cancel'"
      >
        {{ data.cancelButtonText || ('app.cancel' | translate) }}
      </mzima-client-button>
      <mzima-client-button
        [color]="data.isConfirmNotDestructive ? 'primary' : 'danger'"
        [mat-dialog-close]="true"
        [data-qa]="'btn-confirm-delete'"
      >
        {{ data.confirmButtonText || ('app.delete' | translate) }}
      </mzima-client-button>
    </ng-container>
  </mat-dialog-actions>
</div>
