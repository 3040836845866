<div
  class="post"
  [ngClass]="{ 'post--feed': feedView }"
  [ngStyle]="{ '--color': color || 'var(--color-neutral-100)' }"
>
  <ng-container *ngIf="post; else spinner">
    <!-- Post -->
    <ng-container *ngIf="!postChanged">
      <div class="post__head">
        <app-post-head
          *ngIf="post.id"
          [post]="post"
          [feedView]="feedView"
          (edit)="edit.emit()"
          (refresh)="refreshPost()"
          [editable]="post.allowed_privileges.includes('update')"
          [deleteable]="post.allowed_privileges.includes('delete')"
          (statusChanged)="statusChangedHandle()"
          (deleted)="deletedHandle()"
        >
        </app-post-head>
      </div>

      <div class="post__twitter" *ngIf="post.source === 'Twitter' && post.data_source_message_id">
        <app-twitter-widget [id]="post.data_source_message_id"></app-twitter-widget>
      </div>

      <div class="post__survey" *ngIf="post.form">
        <h3>{{ post.form.name }}</h3>
        <p>{{ post.form.description }}</p>
      </div>

      <h2 *ngIf="post.title?.length || post.content?.length" data-qa="postTitleValue">
        {{ post.title || post.content }}
      </h2>
      <div class="post__metadata-container">
        <app-post-metadata *ngIf="post.id" [post]="post"></app-post-metadata>
      </div>

      <div class="post__description" *ngIf="post.title?.length && post.content?.length">
        <p [innerHTML]="post.content" data-qa="postDescriptionValue"></p>
      </div>

      <ng-container *ngFor="let post_content of post.post_content; let i = index">
        <ng-container
          *ngIf="
            isManagePosts ||
            userId === post?.user_id ||
            (i === 0 && post_content?.completed) ||
            (i !== 0 &&
              post_content?.completed &&
              ((post_content?.show_when_published && post.status === 'published') ||
                !post_content?.show_when_published))
          "
        >
          <ng-container
            *ngFor="let field of post_content.fields | sortByField : 'priority' : 'asc'"
          >
            <div
              *ngIf="field.input !== 'tags' ? field.value : field.value?.length"
              class="post__group"
            >
              <h3>{{ field.label }}</h3>

              <ng-container *ngIf="field.input === 'tags'">
                <ng-container *ngFor="let category of field.value">
                  <div class="category" *ngIf="category.tag">
                    <mat-icon svgIcon="tag" class="category__icon"></mat-icon>
                    {{ category.tag }}
                  </div>
                  <ng-container *ngIf="category.children">
                    <ng-container *ngFor="let child of category.children">
                      <div class="category__child">
                        <mat-icon svgIcon="tag" class="category__icon"></mat-icon>
                        {{ child.tag }}
                      </div>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </ng-container>

              <ng-container *ngIf="field.input === 'location'">
                <app-map-with-marker
                  class="map"
                  [marker]="field.value.value"
                  [color]="color || 'var(--color-neutral-100)'"
                  [type]="post.source?.toLowerCase() || 'default'"
                  tabindex="-1"
                  data-qa="postLocationMap"
                ></app-map-with-marker>
                <p data-qa="postLocationValues">
                  {{ field.value.value.lat }} {{ field.value.value.lon }}
                </p>
              </ng-container>

              <ng-container *ngIf="field.input === 'date'">
                <p data-qa="postDateValue">{{ getDate(field.value.value, 'LL') }}</p>
              </ng-container>

              <ng-container *ngIf="field.input === 'datetime'">
                <p data-qa="postDateTimeValue">{{ getDate(field.value.value, 'LL LTS') }}</p>
              </ng-container>

              <ng-container *ngIf="field.input === 'time'">
                <p data-qa="postTimeValue">{{ getDate(field.value.value, 'LTS') }}</p>
              </ng-container>

              <ng-container *ngIf="field.input === 'radio'">
                <p>{{ field.value.value || '-' }}</p>
              </ng-container>

              <ng-container *ngIf="field.input === 'number'">
                <p data-qa="postNumberValue">{{ field.value.value }}</p>
              </ng-container>

              <ng-container *ngIf="field.input === 'text'">
                <ng-container *ngIf="field.label === 'Media Link'">
                  <a *ngIf="field.value.value" [href]="field.value.value" target="_blank">{{
                    field.value.value
                  }}</a>
                  <p *ngIf="!field.value.value">-</p>
                </ng-container>
                <ng-container *ngIf="field.label !== 'Media Link'">
                  <p>{{ field.value.value || '-' }}</p>
                </ng-container>
              </ng-container>

              <ng-container *ngIf="field.input === 'textarea'">
                <p [innerHTML]="field.value.value" data-qa="longTextValue"></p>
              </ng-container>

              <ng-container *ngIf="field.input === 'checkbox'">
                <span *ngIf="!field.options.length">-</span>
                <ul class="post--checkbox-list" *ngIf="field.value?.value?.length">
                  <li *ngFor="let option of field.value.value">
                    <p>{{ option }}</p>
                  </li>
                </ul>
              </ng-container>

              <ng-container *ngIf="field.input === 'video'">
                <span *ngIf="!field.value.value">-</span>
                <div *ngIf="field.value.value">
                  <iframe
                    id="player"
                    [src]="getVideoUrlForField(field)"
                    width="400"
                    height="250"
                  ></iframe>
                  <div>
                    <a [href]="field.value.value" target="_blank">{{ field.value.value }}</a>
                  </div>
                </div>
              </ng-container>

              <ng-container *ngIf="field.input === 'upload' && field.value.mediaSrc">
                <span *ngIf="!field.value.value">-</span>
                <div class="post__media">
                  <img *ngIf="field.value.value" [src]="field.value.mediaSrc" [alt]="post.title" />
                  <span *ngIf="field.value?.mediaCaption?.length" class="post__media__caption">{{
                    field.value.mediaCaption
                  }}</span>
                </div>
              </ng-container>

              <ng-container
                *ngIf="field.input === 'upload' && field.value.length === 1 && field.value[0].url"
              >
                <span *ngIf="!field.value[0].value">-</span>
                <div class="post__media">
                  <img *ngIf="field.value[0].value" [src]="field.value[0].url" [alt]="post.title" />
                  <span *ngIf="field.value[0].caption?.length" class="post__media__caption">{{
                    field.value[0].caption
                  }}</span>
                </div>
              </ng-container>

              <div class="post__images" *ngIf="field.input === 'image' && field.value.length > 0">
                <div class="post__images__item" *ngFor="let mediaFile of field.value">
                  <img
                    *ngIf="mediaFile.value"
                    [src]="this.sanitizer.bypassSecurityTrustUrl(mediaFile.url)"
                    [alt]="post.title"
                  />
                  <span *ngIf="mediaFile.caption?.length" class="post__images__item__caption">{{
                    mediaFile.caption
                  }}</span>
                </div>
              </div>

              <div
                class="post__documents"
                *ngIf="field.input === 'document' && field.value.length > 0"
              >
                <a
                  class="post__documents__item"
                  *ngFor="let mediaFile of field.value"
                  [href]="this.sanitizer.bypassSecurityTrustUrl(mediaFile.url)"
                  target="_blank"
                >
                  <img class="post__documents__item__thumbnail" [src]="mediaFile.icon" />
                  <div class="post__documents__item__details">
                    <div class="filename">{{ mediaFile.filename }}</div>
                    <div class="filesize">{{ mediaFile.fileSize }}</div>
                  </div>
                </a>
              </div>

              <div class="post__audio" *ngIf="field.input === 'audio' && field.value.length > 0">
                <div class="post__audio__item" *ngFor="let mediaFile of field.value">
                  <span class="post__audio__item__filename">{{ mediaFile.filename }}</span>
                  <audio
                    controls
                    [src]="this.sanitizer.bypassSecurityTrustUrl(mediaFile.url)"
                  ></audio>
                </div>
              </div>

              <ng-container *ngIf="field.input === 'relation'">
                <a [href]="field.value.postUrl" target="_blank">
                  {{ field.value.postTitle }}
                </a>
              </ng-container>

              <ng-container *ngIf="field.input === 'select'">
                <p>{{ field.value.value || '-' }}</p>
              </ng-container>

              <ng-container *ngIf="field.input === 'markdown'">
                <span class="code-container">
                  <code>{{ field.value.value || '-' }}</code>
                </span>
              </ng-container>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
      <!-- Skeleton Loader -->
      <div *ngIf="postChanged" class="loader">
        <div class="loader__post-head">
          <span class="loader__post-head-item"></span>
          <span class="loader__post-head-item"></span>
          <span class="loader__post-head-item"></span>
        </div>
        <div>
          <div class="loader__spinner">
            <app-spinner></app-spinner>
          </div>
        </div>
        <div class="loader__post-survey"></div>
        <div class="loader__post-title"></div>
        <div class="loader__metadata">
          <div class="loader__avatar"></div>
          <div class="">
            <div class="loader__metadata-top"></div>
            <div class="loader__metadata-bottom"></div>
          </div>
        </div>
        <div class="loader__content"></div>
      </div>
      <!--  -->
    </ng-container>
  </ng-container>
</div>
<ng-container *ngIf="isManagePosts && !postChanged">
  <app-post-conversation
    *ngIf="post && post.source === 'SMS'"
    [post]="post"
  ></app-post-conversation>
</ng-container>
<ng-template #spinner>
  <app-spinner *ngIf="isPostLoading" class="spinner"></app-spinner>
</ng-template>
